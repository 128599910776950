import type { visualization } from "@fscrypto/domain";
import type { WorkItemType } from "@fscrypto/domain/work-item";
import {
  AreaChartIcon,
  BarChartFilledIcon,
  LineChartIcon,
  PieChartFilledIcon,
  ScatterChartIcon,
  ScorecardIcon,
} from "@fscrypto/ui";
import clsx from "clsx";
import {
  BarChartBigIcon,
  ChevronRightSquare,
  FolderIcon,
  FolderOpenIcon,
  LayoutDashboard,
  SheetIcon,
} from "lucide-react";

interface FileTreeIconProps {
  type: WorkItemType;
  size?: "sm" | "md" | "lg";
  active?: boolean;
  visType?: visualization.VisualizationType | "table" | "histogram";
  grayScale?: boolean;
}

/*
 * This is a simple component that determines which icon to render depending on the `type` and it's `active` state
 */
export const FileTreeIcon = ({ type, active, visType, size = "md" }: FileTreeIconProps) => {
  switch (true) {
    case type === "collection" && active:
      return (
        <FolderOpenIcon
          className={clsx("mr-1 flex-shrink-0 text-gray-500 group-hover:text-gray-900 dark:group-hover:text-white", {
            "size-3": size === "sm",
            "size-4": size === "md",
            "size-6": size === "lg",
          })}
        />
      );
    case type === "collection" && !active:
      return (
        <FolderIcon
          className={clsx("mr-1 flex-shrink-0 text-gray-500 group-hover:text-gray-900 dark:group-hover:text-white", {
            "size-3": size === "sm",
            "size-4": size === "md",
            "size-6": size === "lg",
          })}
        />
      );
    case type === "query":
      return (
        <ChevronRightSquare
          className={clsx("text-orange-60 group-hover:text-orange-60 mr-1 flex-shrink-0", {
            "size-3": size === "sm",
            "size-4": size === "md",
            "size-6": size === "lg",
          })}
        />
      );
    case type === "dashboard":
      return (
        <LayoutDashboard
          className={clsx("text-green-60  group-hover:text-green-60 mr-1 flex-shrink-0", {
            "size-3": size === "sm",
            "size-4": size === "md",
            "size-6": size === "lg",
          })}
        />
      );
    case type === "table":
      return <SheetIcon className="text-blue-60 group-hover:text-blue-60 size-4 mr-1 flex-shrink-0" />;
    case type === "visualization":
      return <VisualizationIcon type={visType} size={size} />;
    default:
      return null;
  }
};

export const VisualizationIcon = ({
  type,
  size = "md",
}: {
  type?: visualization.VisualizationType | "table" | "histogram";
  size: "sm" | "md" | "lg";
}) => {
  const sizeClass = size === "sm" ? "size-3" : size === "md" ? "size-4" : "size-6";
  const classes = `mr-1 flex-shrink-0 ${sizeClass} text-primary/80`;
  switch (true) {
    case type === "bar":
      return <BarChartFilledIcon className={classes} />;
    case type === "line":
      return <LineChartIcon className={classes} />;
    case type === "scatter":
      return <ScatterChartIcon className={classes} />;
    case type === "bar_line":
      return <BarChartFilledIcon className={classes} />;
    case type === "area":
      return <AreaChartIcon className={classes} />;
    case type === "donut":
      return <PieChartFilledIcon className={classes} />;
    case type === "big_number":
      return <ScorecardIcon className={classes} />;
    case type === "table":
      return <SheetIcon className={classes} />;
    default:
      return <BarChartBigIcon className={classes} />;
  }
};
